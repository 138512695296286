import React from "react";
import PropTypes from "prop-types";
import { Outlet, Navigate, useLocation } from "react-router-dom";

import { AuthConsumer } from "../../contexts/AuthProvider";

const RequireAuth = props => {
  const { allowedRoles, requiresPoa } = props;
  const location = useLocation();
  const authCtxt = AuthConsumer();
  const { user, elevatedUser, isPoaAgent } = authCtxt;
  const actualUser = elevatedUser || user;

  return user &&
    (allowedRoles?.includes(actualUser.Role) || requiresPoa === isPoaAgent) ? (
    <Outlet />
  ) : user ? (
    <Navigate to={"/unauthorised"} state={{ from: location }} replace />
  ) : (
    <Navigate to={"/?login=1"} state={{ from: location }} replace />
  );
};

export default RequireAuth;

RequireAuth.propTypes = {
  allowedRoles: PropTypes.array,
  requiresPoa: PropTypes.bool
};
