import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CircularProgress, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";

import DashboardPaper from "../../../theme/styled/DashboardPaper";
import useMessage from "./useMessage";
import FairButton from "../../../theme/styled/FairButton";
import { formatSafariFix } from "../../../helpers/functions";

import RecipientsReadTable from "./RecipientsReadTable/recipientsReadTable";

const Message = props => {
  const {
    cannotSendMessages,
    state,
    isReply,
    handleReply,
    handleDocumentDownload,
    closeMessage
  } = useMessage(props);
  const { loaded, messageThread, attachedDocuments } = state;
  const isBulkMessage =
    messageThread[0]?.Audience && messageThread[0]?.Audience !== ""
      ? true
      : false;
  const recipientsRead = messageThread[0]?.RecipientsRead || null;

  return !loaded ? (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <>
      {!isReply && (
        <Box
          sx={{
            display: "flex",
            mt: 3
          }}
        >
          <Box sx={{ width: "50%" }}>
            <FairButton onClick={closeMessage} sx={{ minWidth: "150px" }}>
              Tillbaka
            </FairButton>
          </Box>
          {!cannotSendMessages && (
            <Box
              sx={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end"
              }}
            >
              <Tooltip
                title={
                  isBulkMessage
                    ? "Detta är ett massmeddelande och kan inte besvaras"
                    : ""
                }
              >
                <div>
                  <FairButton
                    disabled={isBulkMessage}
                    onClick={handleReply}
                    sx={{ minWidth: "150px" }}
                  >
                    Svara
                  </FairButton>
                </div>
              </Tooltip>
            </Box>
          )}
        </Box>
      )}
      <Box>
        {attachedDocuments?.length > 0 && (
          <DashboardPaper
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              marginTop: 1,
              marginBottom: 1,
              padding: 1,
              paddingLeft: { xs: 2, md: 2.5 },
              paddingRight: { xs: 2, md: 2.5 }
            }}
          >
            <Typography variant="h7">Bilagor: </Typography>
            {attachedDocuments.map((document, key) => (
              <Box
                key={key}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  mx: 1,
                  cursor: "pointer"
                }}
                onClick={handleDocumentDownload(document)}
              >
                <IconButton title={`Ladda ner ${document.FileName}`}>
                  {document.Downloaded ? (
                    <DownloadDoneIcon fontSize="small" />
                  ) : document.Downloading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <DownloadIcon fontSize="small" />
                  )}
                </IconButton>
                <Typography variant="body1">{document.FileName}</Typography>
              </Box>
            ))}
          </DashboardPaper>
        )}
      </Box>
      {messageThread?.map((message, key) => (
        <DashboardPaper
          key={message.MessageId}
          sx={{
            marginTop: key < 1 ? (attachedDocuments?.length > 0 ? 1 : 3) : 4
          }}
        >
          <Box sx={{ display: "flex", mb: 0 }}>
            <Box sx={{ width: "50%" }}>
              {/* <Typography variant="h7">Subject: {message.Subject}</Typography>
              <br /> */}
              <Typography variant="h7">
                {message.SenderRole === "Admin" ||
                message.SenderRole === "Backoffice"
                  ? "Fair Investments"
                  : message.SenderName && message.SenderName}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end"
              }}
            >
              <Typography variant="h7">
                {formatSafariFix(message.Date, "yyyy-MM-dd HH:mm")}
              </Typography>
            </Box>
          </Box>
          <Box>
            <br />
            <Typography
              variant="h8"
              sx={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{
                __html: message.MessageBody
              }}
            />
          </Box>
        </DashboardPaper>
      ))}
      {recipientsRead && (
        <DashboardPaper>
          <Box>
            <Typography variant="h6" align="left">
              Meddelande läst
            </Typography>
          </Box>
          <RecipientsReadTable recipients={recipientsRead} />
        </DashboardPaper>
      )}
    </>
  );
};

Message.propTypes = {
  message: PropTypes.object,
  closeMessage: PropTypes.func
};

export default Message;
