import React from "react";
import PropTypes from "prop-types";

import {
  Box,
  Typography,
  IconButton,
  Button,
  Card,
  CardContent
} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AuthConsumer } from "../../../contexts/AuthProvider";

const ContactCard = props => {
  const auth = AuthConsumer();
  const { elevatedUser } = auth;
  console.log(elevatedUser, "elevatedUser");

  const { title, icon, text, image } = props;

  return (
    <Box
      sx={{
        height: "100%",
        maxWidth: { md: 400 },
        position: "relative",
        mx: "auto"
      }}
    >
      <Card
        sx={{
          height: "100%",
          position: "relative",
          overflow: "hidden",
          borderRadius: 2,
          transition: "background-color 0.3s ease, color 0.3s ease",
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "0% 25%",
          backgroundRepeat: "no-repeat",
          boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
          "&:hover": {
            cursor: "pointer"
          },
          "&:hover .hover-mask": {
            opacity: 1 // Reveal the mask on hover
          },
          "&:hover .hover-text": {
            color: "primary.main" // Blue theme for text on hover
          },
          "&:hover .hover-icon": {
            color: "primary.main" // Blue theme for icon on hover
          },
          "&:hover .hover-button": {
            backgroundColor: "primary.main", // Reverse button colors
            color: "#ffd5a3"
          }
        }}
      >
        {/* Transparent Mask */}
        <Box
          className="hover-mask"
          sx={{
            height: "100%",
            position: "absolute",
            inset: 0,
            backgroundColor: "#fff",
            opacity: 0, // Initial state is transparent
            transition: "opacity 0.3s ease",
            zIndex: 2 // Place above the background but behind content
          }}
        />

        <CardContent
          sx={{
            position: "relative",
            zIndex: 2,
            color: "#fff",
            padding: 3,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <IconButton
              className="hover-icon"
              sx={{
                marginBottom: 2,
                borderRadius: "50%",
                padding: 2,
                alignSelf: "center",
                transition: "color 0.3s ease",
                color: "common.white"
              }}
              disableRipple
            >
              <FontAwesomeIcon icon={icon} size="2x" />
            </IconButton>
            <Typography
              variant="h5"
              component="p"
              align="center"
              gutterBottom
              className="hover-text"
              sx={{
                color: "common.white",
                fontWeight: "bold",
                transition: "color 0.3s ease"
              }}
            >
              {title}
            </Typography>
          </Box>
          <Typography
            variant="subtitle2"
            align="center"
            className="hover-text"
            sx={{
              color: "common.white",
              padding: 1,
              borderRadius: 1,
              mb: 2,
              transition: "color 0.3s ease"
            }}
          >
            {text}
          </Typography>
          <Button
            className="hover-button"
            variant="contained"
            endIcon={<ArrowRightAltIcon />}
            sx={{
              backgroundColor: "#ffd5a3",
              color: "primary.main",
              alignSelf: "center",
              transition: "color 0.3s ease, background-color 0.3s ease"
            }}
          >
            Kontakta mig
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

ContactCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.object,
  text: PropTypes.string,
  image: PropTypes.string
};

export default ContactCard;
