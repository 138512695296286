import React, { useState } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

import FairModalBox from "../../../theme/styled/FairModalBox";

const NoticeModal = props => {
  const [open, setOpen] = useState(true);
  const { p1, p2, p3, onClose } = props;

  const toggleModal = () => {
    setOpen(!open);
  };

  return (
    <div>
      <Modal open={open}>
        <FairModalBox sx={{ height: 375 }}>
          <Box
            sx={{
              height: 50,
              px: 4,
              pb: 5
            }}
          >
            <Typography variant="h6" color="primary.main" sx={{ mb: 1 }}>
              Information
            </Typography>
          </Box>
          <Box
            sx={{
              height: 210,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexFlow: "column",
              px: 4
            }}
          >
            <Typography
              variant="body2"
              align="left"
              color="black.common"
              sx={{ mb: 1 }}
            >
              {p1}
            </Typography>
            <Typography
              variant="body2"
              align="left"
              color="black.common"
              sx={{ mb: 1 }}
            >
              {p2}
            </Typography>
            <Typography
              variant="body2"
              align="left"
              color="black.common"
              sx={{ mb: 1 }}
            >
              {p3}
            </Typography>
          </Box>
          <Box
            sx={{
              height: 100,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexFlow: "column",
              px: 4
            }}
          >
            <div>
              <Button
                fullWidth
                onClick={onClose || toggleModal}
                sx={{
                  color: "primary.lighter",
                  my: 1
                }}
              >
                Fortsätt
              </Button>
            </div>
          </Box>
        </FairModalBox>
      </Modal>
    </div>
  );
};

export default NoticeModal;

NoticeModal.propTypes = {
  p1: PropTypes.string,
  p2: PropTypes.string,
  p3: PropTypes.string,
  onClose: PropTypes.func
};
