import { useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useAxios from "../hooks/useAxios";
import useErrorHandler from "./useErrorHandler";

const EXCLUDED_PATHS = ["/reset/", "/unauthorised"];

const useAuthProvider = () => {
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const errorHandler = useErrorHandler();

  const [isLoaded, setIsLoaded] = useState(false);
  const [user, setUser] = useState(null);
  const [elevatedUser, setElevatedUser] = useState(null);
  const [isPoaAgent, setIsPoaAgent] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // check if path should be excluded from auth useEffect
  const isExcludedPath = EXCLUDED_PATHS.some(path => {
    return pathname.includes(path);
  });

  const login = useCallback(
    (user, elevatedUser = false, from = false) => {
      setUser(user);
      if (elevatedUser) {
        setElevatedUser(elevatedUser);
      } else if (user.Role === "Backoffice" || user.Role === "Admin") {
        setElevatedUser(user);
      }

      if (user?.IsPoaAgent) {
        setIsLoaded(true);
        setIsPoaAgent(true);
        if (!elevatedUser) setElevatedUser(user);
        if (from && from !== "/") return navigate(from);

        return navigate("/poa");
      }

      setIsLoaded(true);
      return from && from !== "/" ? navigate(from) : navigate("/dashboard");
    },
    [navigate]
  );

  const logout = useCallback(
    // promptLogin = true needs fixing as it seems is being memoised (always true even if false is passed), because of useCallback wrapper??
    async (promptLogin = true) => {
      const { axiosInstance } = initAxios("auth");

      try {
        await axiosInstance.get("auth/logout");
        setUser(null);
        setIsPoaAgent(false);
        setElevatedUser(null);
        return navigate(`/${promptLogin ? "?login=1" : ""}`);
      } catch (err) {
        errorHandler.serverError(err);
      }
    },
    [initAxios, navigate, errorHandler]
  );

  // check auth cookie/token useEffect
  useEffect(() => {
    if (!isLoaded) {
      const getAuth = async () => {
        const { axiosInstance, axiosController } = initAxios("auth");
        axiosGlobalController.current = axiosController;

        try {
          const userResponse = await axiosInstance.get(`/auth/token`);
          const { user, elevatedUser } = userResponse.data;

          if (!user) {
            return setIsLoaded(true);
          }
          if (elevatedUser) {
            if (user?.IsPoaAgent || elevatedUser?.IsPoaAgent)
              setIsPoaAgent(true);
            setUser(user);
            setElevatedUser(elevatedUser);
            return setIsLoaded(true);
          } else {
            setUser(user);
            return setIsLoaded(true);
          }
        } catch (err) {
          if (err?.response?.data?.msg === "Invalid token") {
            setIsLoaded(true);
            // call logout to clean up any invalid cookies
            return logout(true);
          }
          errorHandler.serverError(err);
        }
      };

      // do not request auth from certain paths i.e reset password
      !isExcludedPath ? getAuth() : setIsLoaded(true);
    }

    return () => axiosGlobalController.current?.abort();
  }, [errorHandler, initAxios, isLoaded, logout, isExcludedPath]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      console.log("cenas");
      if (document.visibilityState === "visible") {
        console.log("User is back on the app!");
        // Add your custom logic here
      }
    };
    const handleFocus = () => console.log("Focus event triggered");

    // Add the event listeners
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("focus", handleFocus);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return {
    isLoaded,
    setIsLoaded,
    user,
    elevatedUser,
    setElevatedUser,
    isPoaAgent,
    setIsPoaAgent,
    setUser,
    login,
    logout
  };
};

export default useAuthProvider;
