import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ThemeProvider from "./theme";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { lazyRetry } from "./helpers/functions";

const Home = lazy(() => lazyRetry(() => import("./pages/Home"), "Home"));
const Poa = lazy(() => lazyRetry(() => import("./pages/Poa"), "Poa"));
const Dashboard = lazy(() =>
  lazyRetry(() => import("./pages/Dashboard"), "Dashboard")
);
const KundLink = lazy(() =>
  lazyRetry(() => import("./pages/KundLink"), "KundLink")
);
const ResetPassword = lazy(() =>
  lazyRetry(() => import("./pages/ResetPassword"), "ResetPassword")
);
const Unauthorised = lazy(() =>
  lazyRetry(() => import("./pages/Unauthorised"), "Unauthorised")
);
const P404 = lazy(() => lazyRetry(() => import("./pages/404"), "404"));
const Terms = lazy(() => lazyRetry(() => import("./pages/Terms"), "Terms"));

import {
  SettingsProvider,
  AuthProvider,
  TimeOutProvider,
  HomeProvider,
  DashboardProvider
} from "./contexts";

import {
  ScrollToTop,
  RequireAuth,
  TimeOutModal,
  AccountSection,
  MessagesSection,
  ReportsSection,
  TransactionsSection,
  AuditLogSection,
  AdminSettingsSection,
  PoaHandlingSection,
  CustomerInsightsSection,
  Profile
} from "./components";

const App = () => {
  return (
    <Suspense
      fallback={
        <Backdrop
          sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }
    >
      <BrowserRouter>
        <ThemeProvider>
          <AuthProvider>
            <TimeOutProvider>
              <SettingsProvider>
                <ScrollToTop />
                <Routes>
                  <Route
                    path="/"
                    replace
                    element={
                      <HomeProvider>
                        <Home />
                      </HomeProvider>
                    }
                  />
                  <Route
                    path="/admin"
                    replace
                    element={
                      <HomeProvider>
                        <Home />
                      </HomeProvider>
                    }
                  />
                  <Route path="/villkor" element={<Terms />} />
                  <Route
                    path="/"
                    element={
                      <RequireAuth
                        allowedRoles={[
                          "Admin",
                          "Backoffice",
                          "AccountManager",
                          "Advisor",
                          "Poa",
                          "Customer"
                        ]}
                      />
                    }
                  >
                    <Route
                      path="/dashboard"
                      replace
                      element={
                        <DashboardProvider>
                          <Dashboard />
                        </DashboardProvider>
                      }
                    >
                      <Route
                        path="/dashboard/meddelanden"
                        element={<MessagesSection />}
                      />
                      <Route
                        path="/dashboard/depaer/:accountId"
                        element={<AccountSection />}
                      />
                      <Route
                        path="/dashboard/rapporter/:documentType"
                        element={<ReportsSection />}
                      />
                      <Route
                        path="/dashboard/transaktioner"
                        element={<TransactionsSection />}
                      />
                      <Route path="/dashboard/profile" element={<Profile />} />
                      <Route
                        path="/dashboard"
                        element={
                          <RequireAuth allowedRoles={["Admin", "Backoffice"]} />
                        }
                      >
                        <Route
                          path="/dashboard/fullmakter"
                          element={<PoaHandlingSection />}
                        />
                        <Route
                          path="/dashboard/insikt"
                          element={<CustomerInsightsSection />}
                        />
                      </Route>
                      <Route
                        path="/dashboard"
                        element={<RequireAuth allowedRoles={["Admin"]} />}
                      >
                        <Route
                          path="/dashboard/logs"
                          element={<AuditLogSection />}
                        />
                        <Route
                          path="/dashboard/settings"
                          element={<AdminSettingsSection />}
                        />
                      </Route>
                    </Route>
                  </Route>
                  <Route
                    path="/"
                    element={
                      <RequireAuth
                        allowedRoles={[
                          "Admin",
                          "Backoffice",
                          "Advisor",
                          "Poa",
                          "Assistant"
                        ]}
                      />
                    }
                  >
                    <Route path="/kund/:token" element={<KundLink />} />
                  </Route>
                  <Route path="/" element={<RequireAuth requiresPoa />}>
                    <Route path="/poa" element={<Poa />} />
                  </Route>
                  <Route path="/reset/:token" element={<ResetPassword />} />
                  <Route path="/unauthorised" element={<Unauthorised />} />
                  <Route path="*" element={<P404 />} />
                </Routes>
                <TimeOutModal />
              </SettingsProvider>
            </TimeOutProvider>
          </AuthProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
