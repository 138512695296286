// import logo from "./logo.svg";
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";

import SectionHeaderPaper from "../../theme/styled/SectionHeaderPaper";
import SectionWrapperBox from "../../theme/styled/SectionWrapperBox";
import SectionInnerLeftWrapBox from "../../theme/styled/SectionInnerLeftWrapBox";
import SectionInnerRightWrapBox from "../../theme/styled/SectionInnerRightWrapBox";
import FairButton from "../../theme/styled/FairButton";

import useResponsive from "../../hooks/useResponsive";
import { Messages, ContactUs } from "..";
import Message from "./Message";
import NewMessage from "./NewMessage";

import useMessages from "./useMessages";

const MessagesSection = () => {
  const isMobile = useResponsive("only", "xs");
  const {
    state,
    audiences,
    handleChangePage,
    handleMailboxChange,
    fetchMessages,
    handleOpenMessage,
    handleCloseMessage,
    handleNewMessage
  } = useMessages();
  const {
    loaded,
    cannotSendMessages,
    page,
    mailbox,
    messages,
    recipients,
    openMessage,
    newMessage
  } = state;

  return !loaded ? (
    <Box
      sx={{
        height: "100%",
        minHeight: "80vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box>
      <SectionHeaderPaper>
        <Typography variant="h3" align="left">
          Meddelanden
        </Typography>
        {!isMobile && (
          <Typography variant="subtitle1">
            Kommunikation till och från backoffice samt din rådgivare
          </Typography>
        )}
      </SectionHeaderPaper>
      <SectionWrapperBox>
        <SectionInnerLeftWrapBox>
          {!openMessage && (
            <>
              <Messages
                page={page}
                mailbox={mailbox}
                audiences={audiences}
                messages={messages}
                handleChangePage={handleChangePage}
                handleMailboxChange={handleMailboxChange}
                openMessage={handleOpenMessage}
              />
              {!newMessage && !cannotSendMessages && (
                <FairButton onClick={handleNewMessage}>
                  Nytt meddelande
                </FairButton>
              )}
            </>
          )}
          {newMessage && (
            <NewMessage
              isMobile={isMobile}
              audiences={audiences}
              replyToRecipient={openMessage || null}
              recipients={recipients || null}
              fetchMessages={fetchMessages}
              closeMessage={handleCloseMessage}
            />
          )}
          {openMessage && (
            <Message
              cannotSendMessages={cannotSendMessages}
              mailbox={mailbox}
              message={openMessage}
              isReply={newMessage ? true : false}
              fetchMessages={fetchMessages}
              handleReply={handleNewMessage}
              closeMessage={handleCloseMessage}
            />
          )}
        </SectionInnerLeftWrapBox>
        <SectionInnerRightWrapBox>
          <ContactUs />
        </SectionInnerRightWrapBox>
      </SectionWrapperBox>
    </Box>
  );
};
export default MessagesSection;
